<template>
  <div class="wrapper" data-app>
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <!--        <div class="steps-list">-->
        <!--          <div-->
        <!--            class="steps-list__item"-->
        <!--            v-for="item in 7"-->
        <!--            :key="item"-->
        <!--            :class="{ active: item === currentStep }"-->
        <!--          >-->
        <!--            {{ item }}-->
        <!--          </div>-->
        <!--        </div>-->
        <SmbOnboardingStepHeader
          :title="getTitleDescriptionAndIsOptional.title"
          :description="getTitleDescriptionAndIsOptional.description"
          :isOptional="getTitleDescriptionAndIsOptional.isOptional"
        />
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="step" step="1">
          <div class="step__content">
            <div class="content-container">
              <v-form v-model="isBaseFormValid" ref="form" lazy-validation>
                <BaseForm
                  :email="form.email"
                  :phone="form.phone"
                  :first-name="form.fistName"
                  :last-name="form.lastName"
                  @emailWasUpdated="form.email = $event"
                  @phoneWasUpdated="form.phone = $event"
                  @firstNameWasUpdated="form.fistName = $event"
                  @lastNameWasUpdated="form.lastName = $event"
                />
              </v-form>
            </div>
          </div>

          <div class="step__controls">
            <SmbActionBtn
              width="80%"
              text="Next"
              :isLoading="isNextBtnLoading"
              :isDisabled="
                form.fistName &&
                form.email &&
                form.phone &&
                form.lastName &&
                !isBaseFormValid
              "
              emitName="next"
              @next="createANewCustomerHandler"
            />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmbOnboardingStepHeader from "@/components/smb-client/SmbOnboardingStepHeader";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import BaseForm from "@/views/smb-client/pages/onboarding/steps/BaseForm.vue";

export default {
  name: "newClientOnboarding",
  components: {
    BaseForm,
    SmbOnboardingStepHeader,
    SmbActionBtn,
  },
  data() {
    return {
      currentStep: 1,
      form: {
        email: "",
        phone: "",
        lastName: "",
        fistName: "",
      },
      isBaseFormValid: false,
      isNextBtnLoading: false,
    };
  },
  created() {
    this.calculateVh();
    window.addEventListener("resize", this.calculateVh);
    window.addEventListener("orientationchange", this.calculateVh);
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    getTitleDescriptionAndIsOptional() {
      switch (this.currentStep) {
        case 1:
          return {
            title: "Start your free trial",
            description:
              "Enter your email, we'll check if you have an account, and help create one if you don't.",
            isOptional: false,
          };
        default:
          return {
            title: "",
            description: "",
            isOptional: false,
          };
      }
    },
  },
  methods: {
    ...mapActions("clientForm", ["sendClientForm"]),
    calculateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");
    },
    async createANewCustomerHandler() {
      try {
        const params = {
          is_automated: true,
          email: this.form.email,
          primary_phone: "1" + this.form.phone,
          name: this.form.fistName,
          last_name: this.form.lastName,
        };

        this.isNextBtnLoading = true;

        const { id } = await this.sendClientForm(params);
        await this.$router.push({
          name: "saas-onboarding",
          params: {
            id: id,
            isFromNewOnboardingPage: true,
          },
        });

        this.isNextBtnLoading = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh) * 100);
  .v-stepper {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 0;
    box-shadow: none !important;
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      padding: 51px 15px 20px 15px;
      background: #1d1d1b;
      border-radius: 0;
      height: auto;
    }
    &__items {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 !important;
    }
    ::v-deep {
      .v-stepper__wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    .step {
      &__content {
        flex: 1;
        padding: 20px 15px 30px 15px;
      }
      &__controls {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &.color {
          display: flex;
          flex-direction: column;
          .arrow-button {
            @media screen and (max-width: 920px) {
              bottom: 30px;
            }
            @media screen and (max-width: 760px) {
              bottom: 0px;
            }
          }
          @media screen and (min-width: 920px) {
            flex-direction: row;
          }
        }
        @media screen and (min-width: 760px) {
          padding-bottom: 30px;
        }
        .button-item {
          width: 100%;
          @media screen and (min-width: 760px) {
            max-width: 315px;
          }
        }
        .add-color {
          margin-right: 20px;
          @media screen and (max-width: 920px) {
            margin: 0 auto 40px auto;
            max-width: 150px;
          }
          ::v-deep {
            .btn-text {
              background: #92c4ff !important;
              color: #1d1d1b;
            }
          }
        }
        .arrow-button {
          width: 20%;
          left: 0;
          position: absolute;
          z-index: 1;
          ::v-deep {
            .v-btn__content {
              .text {
                display: none;
              }
            }
          }
          @media screen and (min-width: 760px) {
            position: absolute;
            left: 50px;
            ::v-deep {
              .btn-text {
                background: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
                color: #1d1d1b;
                max-width: 150px;
                width: auto;
                .mdi-arrow-left {
                  margin-right: 20px;
                }
                &:after,
                &:before {
                  display: none;
                }
                .v-ripple__container {
                  display: none;
                }
              }
              .v-btn__content {
                .text {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  .steps-list {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &__item {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 50%;
      background: #585757;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      margin-right: 13px;
      position: relative;
      &:after {
        content: "";
        height: 2px;
        width: 13px;
        position: absolute;
        right: -13px;
        background: #585757;
        top: 50%;
        transform: translateY(-50%);
      }
      &.active {
        background: #f5ede2;
        color: #1d1d1b;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
}
.content-container {
  width: 100%;
  max-width: 500px;
  margin: 30px auto 20px auto;
  ::v-deep {
    .v-color-picker {
      margin: 0 auto;
    }
  }
}
</style>
